import createProps from '@kissui/helpers/src/props.helpers'
import { isIOS, isAndroid, isHuawei } from '@kissui/helpers/src/useragent.helpers'
import { APP_APPLE_LINK, APP_ANDROID_LINK, APP_HUAWEI_LINK } from '@kissui/components'
import { handlePromoClick } from '@kissui/helpers/src/gtmEvents'
import { getMarketCode } from '@kissui/helpers/src/dataLayer'

class AppBanner extends HTMLElement {
    constructor() {
        super()
        this.boundClose = this.close.bind(this)
        this.boundhandlePromoClick = this.handleClick.bind(this)
        this.hasMoved = false
        this.isIOS = isIOS()
        this.isAndroid = isAndroid()
        this.isHuawei = isHuawei()
        this.sessionStorage = sessionStorage.getItem('app-banner-closed')
    }

    connectedCallback() {
        // If app-banner doesn't need to be displayed
        if (this.isClosed() || this.hasStore()) {
            // If banner is not rendered we need to display none the nb-app-banner tag to not loose focus on elements in the back
            this.style.display = 'none'
            // We need to remove the old smartbanner (and reset html margin)
            this.observeSmartBanner()
        }

        // render only if store is available
        if (!this.hasStore()) {
            return false
        }

        // Those 2 lines should be in 'else' after moved, but we need it here to display in storybook
        this.props = createProps(this.attributes)
        this.render()

        if (!this.hasMoved) {
            // First time : before app-banner has been moved to the right place
            // We need to render and inject the app-banner in the right place
            this.injectionScript()
        } else {
            // Second time : after app-banner has been moved to the right place
            // We need to remove the old smartbanner (and reset html margin)
            this.observeSmartBanner()
        }
    }

    hasStore() {
        return this.isIOS || this.isAndroid
    }

    isClosed() {
        return this.sessionStorage === 'true'
    }

    render() {
        const { id, name, creative, position } = this.props.campaign
        const {
            banner_a11y,
            heading,
            first_line,
            second_line_apple,
            second_line_android,
            second_line_huawei,
            label_close
        } = this.props.copywriting

        let headingRender = ''
        if (heading) {
            headingRender = `<h2 class="t-xs-700-sl">${heading}</h2>`
        }

        let firstLineRender = ''
        if (first_line) {
            firstLineRender = `<p class="t-2xs-500-sl">${first_line}</p>`
        }

        let second_line = ''
        if (this.isHuawei) {
            // We need to check Huawei before Android (because Huawei is Android)
            second_line = second_line_huawei
        } else if (this.isAndroid) {
            second_line = second_line_android
        } else {
            second_line = second_line_apple
        }

        let secondLineRender = ''
        if (second_line !== '') {
            secondLineRender = `<p class="t-2xs-500-sl">${second_line}</p>`
        }

        this.innerHTML = `<nb-container
                campaign_id="${id}"
                campaign_name="${name}"
                campaign_creative="${creative}"
                campaign_position="${position}"
                classname="pt3 pb3"
                contrast="light">
                <aside aria-label="${banner_a11y}">
                    <nb-cta
                        variation="navigation"
                        contrast="light"
                        icon_right="24/symbol/close"
                        label="${label_close}"
                        campaign_id="${id}"
                        campaign_name="${name}"
                        campaign_creative="${creative}"
                        campaign_position="${position}">
                    </nb-cta>
                    <nb-icon icon="logotypes/nespresso/nespresso-monogram-dark"></nb-icon>
                    <div class="text">
                        ${headingRender}
                        ${firstLineRender}
                        ${secondLineRender}
                    </div>
                    ${this.renderAppLink()}
                </aside>
            </nb-container>`

        this.closeBtn = this.querySelector('button')
        this.appLink = this.querySelector('a')

        this.observeFixedElements()

        this.bindEvents()

        if (!this.isClosed()) {
            this.style.display = 'block'
        }
    }

    observeFixedElements() {
        const appBanner = document.querySelector('nb-app-banner')
        const plp = document.querySelector('nb-plp')

        if (plp && appBanner) {
            const observer = new MutationObserver(function (mutations) {
                const el = document.querySelector('plp-navigation')
                mutations.forEach(function (mutation) {
                    if (mutation.type === 'attributes') {
                        const isSticky = el.getAttribute('sticky')
                        appBanner.style.removeProperty('top')
                        appBanner.classList.remove('is-plp-nav-positioned')

                        if (isSticky) {
                            if (isSticky === 'true') {
                                const navigationProps = el.getBoundingClientRect()
                                const navigationBottom = navigationProps.bottom

                                appBanner.classList.add('is-plp-nav-positioned')
                                appBanner.style.top = `${navigationBottom}px`
                            }
                        }
                    }
                })
            })

            observer.observe(plp, {
                attributes: true,
                childList: true,
                subtree: true
            })
        }
    }

    renderAppLink() {
        const { label_link } = this.props.copywriting
        let appUrl = ''
        if (this.isHuawei) {
            // We need to check Huawei before Android (because Huawei is Android)
            appUrl = APP_HUAWEI_LINK
        } else if (this.isAndroid) {
            appUrl = APP_ANDROID_LINK
        } else {
            // Apple markets - new iOS app with switch case
            const marketCode = getMarketCode()
            switch (marketCode) {
                case 'us':
                    appUrl = APP_APPLE_LINK.us
                    break
                case 'uk':
                    appUrl = APP_APPLE_LINK.uk
                    break
                case 'kr':
                    appUrl = APP_APPLE_LINK.kr
                    break
                default:
                    appUrl = APP_APPLE_LINK.default
                    break
            }
        }

        return `<a href="${appUrl}" target="_blank" aria-label="${label_link}">
            <nb-icon icon="24/symbol/chevron-right"></nb-icon>
        </a>`
    }

    close() {
        this.classList.add('close')
        this.removeBodyMargin()
        this.setAttribute('aria-hidden', 'true')
        sessionStorage.setItem('app-banner-closed', 'true')
        this.sessionStorage = 'true'
    }

    handleClick() {
        const {
            campaign,
            copywriting: { label_link }
        } = this.props

        if (!campaign.id) {
            return ''
        }

        handlePromoClick({
            campaign,
            cta_name: `DOWNLOAD - ${label_link}`
        })
    }

    observeSmartBanner() {
        const bannerEle = document.querySelector('.smartbanner')
        if (bannerEle) {
            bannerEle.remove()
            this.setBodyMargin()
            return
        }
        const self = this
        const observer = new MutationObserver(mutations => {
            mutations.forEach(function (mutation) {
                mutation.addedNodes.forEach(function (added_node) {
                    if (added_node.nodeType === 1 && added_node.classList.contains('smartbanner')) {
                        added_node.remove()
                        self.setBodyMargin()
                        observer.disconnect()
                    }
                })
            })
        })
        observer.observe(document.body, { subtree: false, childList: true })
    }

    setBodyMargin() {
        if (this.isClosed()) {
            document.documentElement.style.removeProperty('margin-top')
        } else {
            document.documentElement.style.marginTop = this.offsetHeight + 'px'
            document.documentElement.style.transition = '0.3s ease-in-out margin-top'
        }
    }

    removeBodyMargin() {
        document.documentElement.style.removeProperty('margin-top')
    }

    async injectionScript() {
        // Wait for the component nb-container to be fully rendered
        await this.ready

        // Before Skip link should be the right place to inset app-banner
        let skipLink = document.getElementsByClassName('skip-links')[0]
        if (skipLink) {
            this.hasMoved = true
            document.body.insertBefore(this, skipLink)
            this.setBodyMargin()
        }
    }

    bindEvents() {
        this.unbindEvents()
        if (this.closeBtn) {
            this.closeBtn.addEventListener('click', this.boundClose)
        }
        if (this.appLink) {
            this.appLink.addEventListener('click', this.boundhandlePromoClick)
        }
    }

    unbindEvents() {
        if (this.closeBtn) {
            this.closeBtn.removeEventListener('click', this.boundClose)
        }
        if (this.appLink) {
            this.appLink.removeEventListener('click', this.boundhandlePromoClick)
        }
    }
}

customElements.get('nb-app-banner') || customElements.define('nb-app-banner', AppBanner)
export default AppBanner
